.fc-event, .fc-event-dot {
    background-color: $primary !important;
}

.fc-event {
    color: #fff !important;
    border: none !important;
}

.fc-day-grid-event {
    padding: 5px !important;
}