.nav-pills-vertical-styled .nav-link.active {
    font-weight: normal;
}

.nav-pills-vertical-styled {
    flex-direction: row;
}

.nav-pills-vertical-styled .nav-item {
    width: 100%;
}