.itembox{
	.icon-wrap{margin-bottom:15px; margin-top:7px;}
	img {max-width:100%}
}

.itemside { position:relative;   display: inline-flex; width:100%;
	.text-wrap{padding-left:15px;  padding-right:7px; }
	.img-wrap{position:relative; border-radius:$border-radius 0 0 $border-radius;}

	img { object-fit: contain; }
	p, .title { margin-bottom: 0.5rem; }

	.img-bg {object-fit:contain; position: absolute; right: 0; bottom:0; max-height:100px; bottom: 0; z-index: 0; }
	.card-body{position:relative; z-index:3;}
}

.items-bordered-wrap{
	.itemside:not(:last-child){
		border-bottom:1px solid $border-color; padding-bottom:10px; margin-bottom:10px;
	}
}

.itemlist {
    padding: 20px;
    border-bottom: 1px solid #eee;
}
