/*! 
 * grid for 24 of 1 
 * made by bootstrap-ecommerce.com
*/
.col-1-24 {
  flex: 0 0 4.1666666667%;
  max-width: 4.1666666667%; }

.col-2-24 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-3-24 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4-24 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-5-24 {
  flex: 0 0 20.8333333333%;
  max-width: 20.8333333333%; }

.col-6-24 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7-24 {
  flex: 0 0 29.1666666667%;
  max-width: 29.1666666667%; }

.col-8-24 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-9-24 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10-24 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-11-24 {
  flex: 0 0 45.8333333333%;
  max-width: 45.8333333333%; }

.col-12-24 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13-24 {
  flex: 0 0 54.1666666667%;
  max-width: 54.1666666667%; }

.col-14-24 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-15-24 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16-24 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-17-24 {
  flex: 0 0 70.8333333333%;
  max-width: 70.8333333333%; }

.col-18-24 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19-24 {
  flex: 0 0 79.1666666667%;
  max-width: 79.1666666667%; }

.col-20-24 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-21-24 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22-24 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-23-24 {
  flex: 0 0 95.8333333333%;
  max-width: 95.8333333333%; }

.col-24, .col-24-24  {
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1-24 {
  margin-left: 4.1666666667%; }

.offset-2-24 {
  margin-left: 8.3333333333%; }

.offset-3-24 {
  margin-left: 12.5%; }

.offset-4-24 {
  margin-left: 16.6666666667%; }

.offset-5-24 {
  margin-left: 20.8333333333%; }

.offset-6-24 {
  margin-left: 25%; }

.offset-7-24 {
  margin-left: 29.1666666667%; }

.offset-8-24 {
  margin-left: 33.3333333333%; }

.offset-9-24 {
  margin-left: 37.5%; }

.offset-10-24 {
  margin-left: 41.6666666667%; }

.offset-11-24 {
  margin-left: 45.8333333333%; }

.offset-12-24 {
  margin-left: 50%; }

.offset-13-24 {
  margin-left: 54.1666666667%; }

.offset-14-24 {
  margin-left: 58.3333333333%; }

.offset-15-24 {
  margin-left: 62.5%; }

.offset-16-24 {
  margin-left: 66.6666666667%; }

.offset-17-24 {
  margin-left: 70.8333333333%; }

.offset-18-24 {
  margin-left: 75%; }

.offset-19-24 {
  margin-left: 79.1666666667%; }

.offset-20-24 {
  margin-left: 83.3333333333%; }

.offset-21-24 {
  margin-left: 87.5%; }

.offset-22-24 {
  margin-left: 91.6666666667%; }

.offset-23-24 {
  margin-left: 95.8333333333%; }

@media (min-width: 576px) {
  
  .col-sm-1-24 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%; }

  .col-sm-2-24 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-sm-3-24 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

  .col-sm-4-24 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-sm-5-24 {
    flex: 0 0 20.8333333333%;
    max-width: 20.8333333333%; }

  .col-sm-6-24 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-sm-7-24 {
    flex: 0 0 29.1666666667%;
    max-width: 29.1666666667%; }

  .col-sm-8-24 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-sm-9-24 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }

  .col-sm-10-24 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-sm-11-24 {
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%; }

  .col-sm-12-24 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-sm-13-24 {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%; }

  .col-sm-14-24 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-sm-15-24 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }

  .col-sm-16-24 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-sm-17-24 {
    flex: 0 0 70.8333333333%;
    max-width: 70.8333333333%; }

  .col-sm-18-24 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-sm-19-24 {
    flex: 0 0 79.1666666667%;
    max-width: 79.1666666667%; }

  .col-sm-20-24 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-sm-21-24 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }

  .col-sm-22-24 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-sm-23-24 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%; }

  .col-sm-24, .col-sm-24-24 {
    flex: 0 0 100%;
    max-width: 100%; }

  .offset-sm-1-24 {
    margin-left: 4.1666666667%; }

  .offset-sm-2-24 {
    margin-left: 8.3333333333%; }

  .offset-sm-3-24 {
    margin-left: 12.5%; }

  .offset-sm-4-24 {
    margin-left: 16.6666666667%; }

  .offset-sm-5-24 {
    margin-left: 20.8333333333%; }

  .offset-sm-6-24 {
    margin-left: 25%; }

  .offset-sm-7-24 {
    margin-left: 29.1666666667%; }

  .offset-sm-8-24 {
    margin-left: 33.3333333333%; }

  .offset-sm-9-24 {
    margin-left: 37.5%; }

  .offset-sm-10-24 {
    margin-left: 41.6666666667%; }

  .offset-sm-11-24 {
    margin-left: 45.8333333333%; }

  .offset-sm-12-24 {
    margin-left: 50%; }

  .offset-sm-13-24 {
    margin-left: 54.1666666667%; }

  .offset-sm-14-24 {
    margin-left: 58.3333333333%; }

  .offset-sm-15-24 {
    margin-left: 62.5%; }

  .offset-sm-16-24 {
    margin-left: 66.6666666667%; }

  .offset-sm-17-24 {
    margin-left: 70.8333333333%; }

  .offset-sm-18-24 {
    margin-left: 75%; }

  .offset-sm-19-24 {
    margin-left: 79.1666666667%; }

  .offset-sm-20-24 {
    margin-left: 83.3333333333%; }

  .offset-sm-21-24 {
    margin-left: 87.5%; }

  .offset-sm-22-24 {
    margin-left: 91.6666666667%; }

  .offset-sm-23-24 {
    margin-left: 95.8333333333%; }

}
@media (min-width: 768px) {

  .col-md-1-24 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%; }

  .col-md-2-24 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-md-3-24 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

  .col-md-4-24 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-md-5-24 {
    flex: 0 0 20.8333333333%;
    max-width: 20.8333333333%; }

  .col-md-6-24 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-md-7-24 {
    flex: 0 0 29.1666666667%;
    max-width: 29.1666666667%; }

  .col-md-8-24 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-md-9-24 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }

  .col-md-10-24 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-md-11-24 {
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%; }

  .col-md-12-24 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-md-13-24 {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%; }

  .col-md-14-24 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-md-15-24 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }

  .col-md-16-24 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-md-17-24 {
    flex: 0 0 70.8333333333%;
    max-width: 70.8333333333%; }

  .col-md-18-24 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-md-19-24 {
    flex: 0 0 79.1666666667%;
    max-width: 79.1666666667%; }

  .col-md-20-24 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-md-21-24 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }

  .col-md-22-24 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-md-23-24 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%; }

  .col-md-24, .col-md-24-24  {
    flex: 0 0 100%;
    max-width: 100%; }

  .offset-md-1-24 {
    margin-left: 4.1666666667%; }

  .offset-md-2-24 {
    margin-left: 8.3333333333%; }

  .offset-md-3-24 {
    margin-left: 12.5%; }

  .offset-md-4-24 {
    margin-left: 16.6666666667%; }

  .offset-md-5-24 {
    margin-left: 20.8333333333%; }

  .offset-md-6-24 {
    margin-left: 25%; }

  .offset-md-7-24 {
    margin-left: 29.1666666667%; }

  .offset-md-8-24 {
    margin-left: 33.3333333333%; }

  .offset-md-9-24 {
    margin-left: 37.5%; }

  .offset-md-10-24 {
    margin-left: 41.6666666667%; }

  .offset-md-11-24 {
    margin-left: 45.8333333333%; }

  .offset-md-12-24 {
    margin-left: 50%; }

  .offset-md-13-24 {
    margin-left: 54.1666666667%; }

  .offset-md-14-24 {
    margin-left: 58.3333333333%; }

  .offset-md-15-24 {
    margin-left: 62.5%; }

  .offset-md-16-24 {
    margin-left: 66.6666666667%; }

  .offset-md-17-24 {
    margin-left: 70.8333333333%; }

  .offset-md-18-24 {
    margin-left: 75%; }

  .offset-md-19-24 {
    margin-left: 79.1666666667%; }

  .offset-md-20-24 {
    margin-left: 83.3333333333%; }

  .offset-md-21-24 {
    margin-left: 87.5%; }

  .offset-md-22-24 {
    margin-left: 91.6666666667%; }

  .offset-md-23-24 {
    margin-left: 95.8333333333%; }

 }
@media (min-width: 992px) {

  .col-lg-1-24 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%; }

  .col-lg-2-24 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-lg-3-24 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

  .col-lg-4-24 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-lg-5-24 {
    flex: 0 0 20.8333333333%;
    max-width: 20.8333333333%; }

  .col-lg-6-24 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-lg-7-24 {
    flex: 0 0 29.1666666667%;
    max-width: 29.1666666667%; }

  .col-lg-8-24 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-lg-9-24 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }

  .col-lg-10-24 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-lg-11-24 {
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%; }

  .col-lg-12-24 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-lg-13-24 {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%; }

  .col-lg-14-24 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-lg-15-24 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }

  .col-lg-16-24 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-lg-17-24 {
    flex: 0 0 70.8333333333%;
    max-width: 70.8333333333%; }

  .col-lg-18-24 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-lg-19-24 {
    flex: 0 0 79.1666666667%;
    max-width: 79.1666666667%; }

  .col-lg-20-24 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-lg-21-24 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }

  .col-lg-22-24 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-lg-23-24 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%; }

  .col-lg-24, .col-lg-24-24 {
    flex: 0 0 100%;
    max-width: 100%; }

  .offset-lg-1-24 {
    margin-left: 4.1666666667%; }

  .offset-lg-2-24 {
    margin-left: 8.3333333333%; }

  .offset-lg-3-24 {
    margin-left: 12.5%; }

  .offset-lg-4-24 {
    margin-left: 16.6666666667%; }

  .offset-lg-5-24 {
    margin-left: 20.8333333333%; }

  .offset-lg-6-24 {
    margin-left: 25%; }

  .offset-lg-7-24 {
    margin-left: 29.1666666667%; }

  .offset-lg-8-24 {
    margin-left: 33.3333333333%; }

  .offset-lg-9-24 {
    margin-left: 37.5%; }

  .offset-lg-10-24 {
    margin-left: 41.6666666667%; }

  .offset-lg-11-24 {
    margin-left: 45.8333333333%; }

  .offset-lg-12-24 {
    margin-left: 50%; }

  .offset-lg-13-24 {
    margin-left: 54.1666666667%; }

  .offset-lg-14-24 {
    margin-left: 58.3333333333%; }

  .offset-lg-15-24 {
    margin-left: 62.5%; }

  .offset-lg-16-24 {
    margin-left: 66.6666666667%; }

  .offset-lg-17-24 {
    margin-left: 70.8333333333%; }

  .offset-lg-18-24 {
    margin-left: 75%; }

  .offset-lg-19-24 {
    margin-left: 79.1666666667%; }

  .offset-lg-20-24 {
    margin-left: 83.3333333333%; }

  .offset-lg-21-24 {
    margin-left: 87.5%; }

  .offset-lg-22-24 {
    margin-left: 91.6666666667%; }

  .offset-lg-23-24 {
    margin-left: 95.8333333333%; }
}
@media (min-width: 1200px) {

  .col-xl-1-24 {
    flex: 0 0 4.1666666667%;
    max-width: 4.1666666667%; }

  .col-xl-2-24 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xl-3-24 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

  .col-xl-4-24 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xl-5-24 {
    flex: 0 0 20.8333333333%;
    max-width: 20.8333333333%; }

  .col-xl-6-24 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xl-7-24 {
    flex: 0 0 29.1666666667%;
    max-width: 29.1666666667%; }

  .col-xl-8-24 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xl-9-24 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }

  .col-xl-10-24 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xl-11-24 {
    flex: 0 0 45.8333333333%;
    max-width: 45.8333333333%; }

  .col-xl-12-24 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xl-13-24 {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%; }

  .col-xl-14-24 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xl-15-24 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }

  .col-xl-16-24 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xl-17-24 {
    flex: 0 0 70.8333333333%;
    max-width: 70.8333333333%; }

  .col-xl-18-24 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xl-19-24 {
    flex: 0 0 79.1666666667%;
    max-width: 79.1666666667%; }

  .col-xl-20-24 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xl-21-24 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }

  .col-xl-22-24 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xl-23-24 {
    flex: 0 0 95.8333333333%;
    max-width: 95.8333333333%; }

  .col-xl-24, .col-xl-24-24 {
    flex: 0 0 100%;
    max-width: 100%; }


  .offset-xl-1-24 {
    margin-left: 4.1666666667%; }

  .offset-xl-2-24 {
    margin-left: 8.3333333333%; }

  .offset-xl-3-24 {
    margin-left: 12.5%; }

  .offset-xl-4-24 {
    margin-left: 16.6666666667%; }

  .offset-xl-5-24 {
    margin-left: 20.8333333333%; }

  .offset-xl-6-24 {
    margin-left: 25%; }

  .offset-xl-7-24 {
    margin-left: 29.1666666667%; }

  .offset-xl-8-24 {
    margin-left: 33.3333333333%; }

  .offset-xl-9-24 {
    margin-left: 37.5%; }

  .offset-xl-10-24 {
    margin-left: 41.6666666667%; }

  .offset-xl-11-24 {
    margin-left: 45.8333333333%; }

  .offset-xl-12-24 {
    margin-left: 50%; }

  .offset-xl-13-24 {
    margin-left: 54.1666666667%; }

  .offset-xl-14-24 {
    margin-left: 58.3333333333%; }

  .offset-xl-15-24 {
    margin-left: 62.5%; }

  .offset-xl-16-24 {
    margin-left: 66.6666666667%; }

  .offset-xl-17-24 {
    margin-left: 70.8333333333%; }

  .offset-xl-18-24 {
    margin-left: 75%; }

  .offset-xl-19-24 {
    margin-left: 79.1666666667%; }

  .offset-xl-20-24 {
    margin-left: 83.3333333333%; }

  .offset-xl-21-24 {
    margin-left: 87.5%; }

  .offset-xl-22-24 {
    margin-left: 91.6666666667%; }

  .offset-xl-23-24 {
    margin-left: 95.8333333333%; }

}

/* by default 24 grid cols full width */
[class^="col-"][class*="-24"]{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/* nogutter for 24 grid cols */
.no-gutters > [class*="col-"]{
  padding-right: 0!important;
  padding-left: 0!important;
}