.navbar-top{padding:0}
.navbar-landing{transition: .5s; padding-top:15px; padding-bottom:15px;  position: fixed; top: 0; z-index: 100; width: 100%;}
.navbar-landing.fixed-top{transition: .5s;  padding-top:7px; padding-bottom:7px; }

.navbar .navbar-nav .nav-item.active {
    background: $primary;
}

.navbar-light .navbar-nav .show > .nav-link {
    color: $link-hover-color;
}

.header-main{padding-top:0.5rem; padding-bottom:0.5rem;box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);background-color: #fff; z-index: 1021 !important;}
.brand-wrap, .navbar-brand {
    .logo{margin-right:7px;  max-height:42px; display:inline-block; }
    .logo-text{margin:0; display:inline-block; font-size:22px; vertical-align:middle}
}

.widget-header{ margin-left:7px; margin-right:7px; display:inline-block; position:relative;
                form{min-width:280px;}
                .title{display:block}
                .notify{ position:absolute; top:-3px; right:-10px}
}

.widget-header a {
    color: #333;
}

.header-main .search-wrap input[type="text"] {
    height: auto;
    padding-top: 0.475rem !important;
    padding-bottom: 0.475rem !important;
    padding-left: .75rem;
}

.header-main .search-wrap .input-icon i {
    top: 5px;
    right: 7px;
    left: auto;
    cursor: pointer;
}

.header-main .search-wrap i {
    color: #888;
}

.header-main .search-wrap .twitter-typeahead, .header-main .search-wrap .tt-menu, .header-main .search-wrap .tt-menu .dropdown-menu {
    width: 100%;
}

.header-main .search-wrap .tt-menu .dropdown-menu {
    padding: 1rem;
}

.widget-header a:hover {
    color: $link-hover-color;
}

.section-pagetop .title-page {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.navbar-toggler:focus {
    outline: none;
}

@media all and (min-width: 1200px) {
    .section-pagetop .title-page {
        max-width: 40%;
    }
}

@media all and (max-width: 990px) {
    .header-main.sticky-top {
        position: relative;
    }
    .header-user-dropdown-menu {
        display: none !important;
    }
}

@media all and (max-width: 1200px) {
    .header-main .widget-header .text-wrap {
        display: none;
    }
}

@media all and (min-width: 991px) and (max-width: 1300px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.6rem !important;
        padding-left: 1.6rem !important;
    }
}

@media all and (min-width: 991px) and (max-width: 1200px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        font-size: 14px !important;
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .navbar-expand-lg .navbar-nav .nav-link i {
        display: none;
    }
}