.nav-tabs {
    border-color: $gray;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-color: $gray $gray transparent;
}

.dashboard-sidebar .badge, .dropdown .badge{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 22px;
    height: 22px;
    line-height: 8px;
    font-size: 11px;
    font-weight: 600;
    border-radius: 50%;
    color: #fff;
    text-align: center;
}
