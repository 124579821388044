a{
    &:hover{
        text-decoration: none;
    }
}

.img-wrap{
    text-align: center;
    display:block;
    img{
        max-width: 100%;
        max-height: 100%;
    }
}
.icon-wrap{
    text-align:center;
}

.zoom-in{
    transition: transform ease .3s;
}
.zoom-wrap:hover .zoom-in {
    transform: scale(1.1);
    transition: transform ease .3s;
}
/* =================  DEFINED VARIABLES =================== */
.icon-xs, .icon-sm, .icon-md, .icon-lg{
    display:inline-block;
    text-align:center
}
.icon-xs{
    width:32px;
    height:32px;
    line-height:32px;
    font-size:16px;
}
.icon-sm{
    width:42px;
    height:42px;
    line-height:42px;
    font-size:22px;
}
.icon-md{
    width:60px;
    height:60px;
    line-height:60px;
    font-size:32px;
}
.icon-lg{
    width:80px;
    height:80px;
    line-height:80px;
    font-size:42px;
}

.img-xs, .img-sm, .img-md, .img-lg, .img-xxs{
    object-fit:cover;
}
.img-xxs{
    width:25px;
    height:25px;
}
.img-xs{
    width:40px;
    height:40px;
}
.img-sm{
    width:80px;
    height:80px;
}
.img-md{
    width:160px;
    height:160px;
}
.img-lg{
    width:400px;
    height:300px;
}


.center-xy{
    top:50%;
    left:50%;
    position:absolute;
    transform:translate(-50%, -50%);
}
.center-y{
    top: 50%;
    position: absolute;
    transform:translateY(-50%)
}
.center-x{
    left: 50%;
    position: absolute;
    transform:translateX(-50%)
}

.center{
    margin-left:auto;
    margin-right:auto;
}
.overflow-h{
    overflow:hidden
}
.overflow-auto {
    overflow: auto !important;
}
.flip-h{
    transform: scaleX(-1);
}

.card-body-lg{
    padding:2rem;
}


.shadow-in{
    box-shadow:inset 0 2px 4px rgba(#333, .4)
}

.vh-100{
    height:100vh
}
.vh-90{
    height:90vh
}
.vh-80{
    height:80vh
}
.vh-70{
    height:70vh
}
.vh-60{
    height:60vh
}
.vh-50{
    height:50vh
}

[class*="hover-"]{
    transition:.5s;
}
.hover-shadow:hover{
    box-shadow:0 4px 15px rgba(#333, .5);
    transition:.5s;
}
.hover-bg:hover{
    @include diagonalGradient($primary, $primary);
    transition:.5s;
}
.hover-zoom:hover{
    transition:.5s;
    transform: scaleX(1);
}

/* ========== input with icon ==========  */
.input-icon{
    position:relative;
    i + input, i + select{
        padding-left:36px;
    }
    i{
        position:absolute;
        color:#ccc;
        z-index:9;
        left: 7px;
        top: 4px;
        width: 24px;
        text-align: center;
        padding:7px 0;
    }
}


.form-inline{
    .form-control-sm[type="number"]{
        max-width:100px
    }
}
/* ========== borders ========== */
.border{
    border:1px solid $border-color;
}
.border-left-white{
    border-left:1px solid rgba(#fff, 1);
}
.border-right-white{
    border-right:1px solid rgba(#fff, 1);
}
.border-top-white{
    border-top:1px solid rgba(#fff, 1) !important;
}
.border-top-gray{
    border-top:1px solid #e0e0e0 !important;
}
.border-bottom-white{
    border-bottom:1px solid rgba(#fff, 1);
}
.border-top-primary-thick{
    border-top:5px solid $primary !important;
}

.circle{
    border-radius: 100%;
}
.round{
    border-radius:200px;
}



/* ============== spacing ============== */
.padding-y{
    padding-top:32px;
    padding-bottom:32px;
}
.padding-top{
    padding-top:32px;
}
.padding-bottom{
    padding-bottom:32px;
}

.padding-y-lg{
    padding-top:60px;
    padding-bottom:50px;
}
.padding-x-md{
    padding-right:30px;
    padding-left:30px;
}
.padding-top-lg{
    padding-top:60px;
}
.padding-bottom-lg{
    padding-bottom:60px;
}

.padding-y-sm{
    padding-top:16px;
    padding-bottom:16px;
}
.padding-top-sm{
    padding-top:16px;
}
.padding-bottom-sm{
    padding-bottom:16px;
}

.nopadding{
    padding:0px;
}
.p0{
    padding:0px;
}
.p5{
    padding:5px;
}
.p7{
    padding:7px;
}
.p10{
    padding:10px;
}
.p15{
    padding:15px;
}
.p20{
    padding:20px;
}
.p30{
    padding:30px;
}
.p40{
    padding:40px;
}
.p50{
    padding:50px;
}
.p60{
    padding:60px;
}

.nomargin{
    margin:0px;
}
.m0{
    margin:0px;
}
.m5{
    margin:5px;
}
.m7{
    margin:7px;
}
.m10{
    margin:10px;
}
.m15{
    margin:15px;
}
.m30{
    margin:30px;
}
.m40{
    margin:40px;
}
.m50{
    margin:50px;
}
.m60{
    margin:60px;
}



.relative{
    position:relative
}
.absolute{
    position:absolute
}

var{
    font-style:normal;
}
figure{
    margin-bottom:0
}


.form-noborder{
    input, select, .btn {
        border:none
    }
    select.form-control-lg:not([size]):not([multiple]){
        height:2.875rem;
    }
}

/* all clearfix */
article:after,
.form-group:after
{
    display: block;
    clear: both;
    content: "";
}

.border-cols:not(:last-child){
    border-bottom:1px solid $border-color;
}
.border-cols > [class*="col-"] {
    overflow: hidden;
    border-color: $border-color;
    border-width: 0 0 0 1px;
    border-style: solid;
}

/* -------------------- small grid gutter --------------------- */
.row-sm {
    margin-right: -10px;
    margin-left: -10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.row-sm > [class^="col"] {
    padding-left:10px;
    padding-right:10px;
}

.display-inline-block {
    display: inline-block !important;
}

.display-inline {
    display: inline !important;
}

.width-auto {
    width: auto !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

@media (min-width: 992px) {
    .pt-lg-6 {
        padding-top: 6rem !important;
    }
    .center-lg-y{
        top: 50%;
        position: absolute;
        transform:translateY(-50%)
    }

}

.list-group-horizontal {
    -ms-flex-direction: row;
    flex-direction: row;
}
.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0;
}

.img-200-200 {
    max-height: 200px;
    max-width: 200px;
}

.img-100-100 {
    max-height: 100px;
    max-width: 100px;
}

.img-80-80 {
    max-height: 80px;
    max-width: 80px;
}

.img-50-50 {
    max-height: 50px;
    max-width: 50px;
}

.img-30-30 {
    max-height: 30px;
    max-width: 30px;
}

.img-15-15 {
    max-height: 15px;
    max-width: 15px;
}

.cursor-pointer:hover {
    cursor:pointer;
}

a.btn.disabled.has-tooltip {
    pointer-events: auto;
}

.counter-box i {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 26px;
    opacity: 0.5;
}

// Dashboard sidenav

.dashboard-sidenav {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: -250px;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;
}

.dashboard-sidenav-close {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.dashboard-sidenav-username {
    color: #fff;
    position: absolute;
    top: 17px;
    left: 17px;
}

@media screen and (max-height: 450px) {
    .dashboard-sidenav {
        padding-top: 15px;
    }
}

#dashboard-sidenav .nav-pills-vertical-styled .nav-link {
    background-color: transparent;
    color: #eee;
}

#dashboard-sidenav .nav-pills-vertical-styled .nav-link.active {
    color: $primary !important;
}

label.not-required:after, .col-form-label.not-required:after {
    content: "" !important;
}

.clr-field {
    display: block !important;
}

.ui-rotatable-handle {
    height: 16px;
    width: 16px;
    cursor: pointer;
    background-image: url(../../../img/icons/rotate.png);
    background-size: 100%;
    left: 2px;
    bottom: 2px;
}

.evo-cp-wrap {
    width: auto !important;
}

.evo-pop {
    padding: 0;
}

.select2-container.hidden-seats .select2-choices, .select2-container.disabled-seats .select2-choices {
    height: calc(2.75rem + 2px);
    border-color: #f1f3f7;
    line-height: 30px;
    background: #f1f3f7;
}

.seating-plan-preview-wrapper {
    width: 1300px;
    height: 673px;
    border: 1px solid #eee;
    position: relative;
    margin: 0 auto;
    overflow: scroll;
    max-width: 100%;
}

.seating-plan-preview-wrapper .seating-plan-preview-stage, .seating-plan-preview-wrapper .seating-plan-preview-section {
    display: block;
    border: 1px solid #c5c5c5;
    font-weight: normal;
    color: #454545;
    padding: 0.5em 0;
    position: absolute;
}

.seating-plan-preview-wrapper .seating-plan-preview-stage h6, .seating-plan-preview-wrapper .seating-plan-preview-section h6 {
    border: 1px solid #dddddd;
    background: #e9e9e9;
    color: #333333;
    font-weight: bold;
    text-align: center;
    margin: 0;
}

.seating-plan-preview-modal-wrapper .seating-plan-preview-section-row-seat {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    display: inline-block;
    margin-left: 10px;
}

.modal-fullscreen {
    height: 100%;
    width: 100%;
    max-width: none;
    max-height: none;
    padding: 0;
    margin: 0;
}

.has-seating-plan .bootstrap-touchspin .input-group-btn-vertical{
    display: none;
}

.seating-plan-preview-wrapper .seating-plan-preview-section:hover, .seating-plan-preview-modal-wrapper .seating-plan-preview-section-row-seat:hover {
    cursor: pointer;
}

.seating-plan-preview-modal-wrapper .seating-plan-preview-section-row-seat {
    background: #f79646;
    text-align: center;
    color: white;
    font-weight: bold;
}

.seating-plan-preview-modal-wrapper .seating-plan-preview-section-row {
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
}

.ui-tooltip {
    z-index: 1111111111111 !important;
}

.modal-backdrop ~ .modal-backdrop
{
    z-index : 1111111 ;
}

.seating-plan-section-tickets-choosen-indicator {
    position: absolute;
    bottom: 3px;
    left: 3px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 11px;
    font-weight: 600;
    border-radius: 50%;
    background: #31708f;
    color: #fff;
}

.seating-plan-preview-section.event-ticket-disabled:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #eee;
    top: 0;
    left: 0;
    opacity: 0.8;
    cursor: initial !important;
}

.cart-eventdate-ticket-qte-hasSeatingPlan ~ .input-group-btn-vertical {
    display: none;
}

.list-check > li::before {
    top: -3px !important;
    left: -20px !important;
    color: inherit !important;
}