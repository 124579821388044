.badge-notification[data-badge]::after {
    content: attr(data-badge);
    position: absolute;
    top: -11px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 11px;
    font-weight: 600;
    border-radius: 50%;
    background: $cyan;
    color: #fff;
}

.badge-notification-right.badge-notification[data-badge]::after {
    right: -10px;
}

.badge-notification-left.badge-notification[data-badge]::after {
    left: -10px;
}

.badge {
    padding: 0.5em;
    font-size: 85%;
    font-weight: normal;
    border-radius: 0;
}