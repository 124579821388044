.card-event{ margin-bottom:1.3rem;
             &:after{content:"";  display:table; clear:both; visibility:hidden; }
             .img-wrap{
                 background-position: center center !important;
                 background-repeat: no-repeat !important;
                 background-size: cover !important;
                 border-radius:$border-radius-sm $border-radius-sm 0 0;
                 overflow:hidden;
                 position:relative;
                 height:220px;
                 text-align: center;
                 img{ max-height:100%; max-width:100%; width:auto; display:inline-block; object-fit:cover;}
             }
             .img-wrap a {
                 height: 100%;
                 width: 100%;
                 display: block;
             }
             .info-wrap{ padding:15px; border-top:1px solid #eee; position: relative;}
             .event-favorite {
                 position: absolute;
                 top: -10px;
                 right: 5px;
                 background: #f9f9f9;
                 border-radius: 50%;
                 padding: 5px 8px;
             }
             .event-favorite a {
                 color: #333;
             }
             .event-favorite button {
                 background: transparent;
                 border: none;
                 padding: 0;
             }
             .event-favorite button:focus {
                 outline: none;
             }
             .action-wrap{padding-top:4px; margin-top:4px}
             .bottom-wrap{padding:15px; border-top:1px solid #eee;}
             .title{margin-top:0;font-size: 1.125rem; color: #2e3e40;font-weight: 600; }
             /* btn-overlay-bottom */
             .btn-overlay {
                 transition:.5s;  opacity:0;
                 left: 0;  bottom: 0;
                 color: #fff;   width: 100%;   padding: 5px 0;     font-size: 12px;   text-align: center;
                 position: absolute;    text-transform: uppercase;
                 background: rgba(0,0,0,0.5);
             }
             &:hover .btn-overlay{ opacity:1;}
             &:hover{box-shadow:0 4px 15px rgba(#999, .3); transition:.5s}
             .event-category {
                 color: #7f818e;
                 border-radius: 5px;
                 background-color: #fff;
                 position: absolute;
                 top: 10px;
                 right: -10px;
                 box-shadow: 0 4px 15px 0 rgba(40,44,53,.06), 0 2px 2px 0 rgba(40,44,53,.08);
                 z-index: 3;
                 padding: .625rem 1.25rem;
                 font-size: 12px;
                 opacity: 0.97;
             }
             .event-date {
                 position: absolute;
                 top: 10px;
                 left: 10px;
                 box-shadow: 0 4px 15px 0 rgba(40,44,53,.06), 0 2px 2px 0 rgba(40,44,53,.08);
                 z-index: 3;
                 font-size: 12px;
                 opacity: 0.97;
                 .event-month {
                     position: relative;
                     padding: 5px 17px;
                     border-top-left-radius: 3px;
                     border-top-right-radius: 3px;
                 }
                 .event-day {
                     font-weight: 900;
                     padding: 10px 17px;
                     border-bottom-left-radius: 3px;
                     border-bottom-right-radius: 3px;
                 }
             }
             .event-info {
                 float: left;
                 max-width: 100%;
             }
             .event-info > h5{

                 white-space: nowrap;
                 text-overflow: ellipsis;
                 width: 100%;
                 overflow: hidden;
             }
             .price-wrap {
                 float: right;
                 position: absolute;
                 right: 10px;
                 bottom: 10px;
             }
             .order-reference {
                 float: right;
                 position: absolute;
                 right: 15px;
                 bottom: 10px;
             }
}

.card-sm{
    .img-wrap{height:180px;}
}

.card-banner{
    display:flex;
    position:relative;
    overflow:hidden;
    background-color:#fff;
    background-size: cover;
    border-radius:$border-radius;
    background-position: center center;
    background-repeat: no-repeat;
    .card-body{background-size: cover; position:relative; z-index:10}
    .img-bg{
        position: absolute; right:0; bottom:0;
        mix-blend-mode: multiply; max-width:100%;
    }
    .overlay{ background-color:rgba(#000, .65); z-index:10;  padding:1.25rem; color:#fff;    }
    .overlay.bottom{ bottom:0; left:0;  position:absolute;  width:100%; }
    .overlay.top{ top:0; left:0; position:absolute;  width:100%; }
    .overlay-cover{ top:0; left:0; right:0; bottom:0; position:absolute;  width:100%; }
    .overlay.left{ top:0; left:0; bottom:0; position:absolute;}

    .text-bottom{ position:absolute; left:0; bottom:0; left:0; z-index:10; width:100%;
                  padding:7px 20px; padding-top:30px;  border-radius:0 0 $border-radius $border-radius;
                  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
                  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
                  color: #fff;
    }
}

.banner-size-lg{ height: 350px; }