.list-inline{margin-bottom:0;}

.list-inline-item{vertical-align:middle}

/* --------- description list --------*/
[class*="dlist-"]{
    margin-bottom:5px;
    dd{ margin-bottom:0}
}

.dlist-inline{
    dt, dd{ display:inline-block; }
}

.dlist-align{
    dt{  width: 90px; float: left; word-wrap: break-word;}
    dd{ margin-left:120px; vertical-align: baseline; }
}
/* -------------------- list.row--------------------- */
ul.row, ul.row-sm{ list-style:none; padding:0; margin-bottom:0; }

/* ================= lists ================= */
[class*="list-"] li:after {
    visibility: hidden;
    display: block;
    content: "";
    overflow: hidden;
    height: 0; clear: both;
}

.list-lg{
    li{margin-bottom:10px;}
}

.list-icon { list-style:none; padding: 0;
             li{margin-bottom:10px; }
             img{ width:30px; vertical-align:middle; margin-right:15px;}
             .icon{width:22px; margin-right:15px; vertical-align:middle; font-size:20px; text-align: center;}
             span{ vertical-align:middle}
}

.list-bullet{list-style: none;  padding: 0;}
.list-bullet li{margin-bottom:7px; position:relative; padding-left:15px;}
.list-bullet li::before {
    content: " ";
    position:absolute;  top:45%;  left:0;
    width: 7px;
    height: 7px;
    margin: 0px 10px 0px 0px;
    background:$secondary;
    border-radius:7px;
}

.list-check{
    > li{ list-style: none; position: relative; }
    > li::before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color:$secondary;  position:absolute; top:0px; left:0;
        content: "\f00c"; font-size:16px;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
    }
}

.list-icon{ list-style:none;  padding: 0;
            li{position:relative; padding-left:30px;}
            .icon{
                position:absolute;  top:3px; left:0; font-size:13px;
            }

}

.dlist-align dt {
    width: 130px;
}

// List with border seperator

.dlist-border > dt{
    width: 200px;
    margin-bottom: 0;
}

.dlist-border > dd{
    margin-left: 230px;
    margin-bottom: 0;
    border-left: 1px solid #f1f3f7;
    padding-bottom: 50px;
    padding-left: 50px;
}

@media (max-width: 991px){
    .dlist-border > dt{
        float: none;
        margin-bottom: 15px;
    }
    .dlist-border > dd{
        margin-left: 0;
        padding-left: 0;
        border: none;
    }
}

// Timeline list

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: $gray;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid $primary;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}