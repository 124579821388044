@charset "UTF-8";

/////////////////// Linear Gradient
// usage: .grad{ @include linearGradient(#cccccc, #666666); }
@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
} 

//////////////////// diagonal gradient
// usage: .grad-diagonal{ @include diagonalGradient(#cccccc, #666666); }
@mixin diagonalGradient($top, $bottom){
      background: $bottom; /* For browsers that do not support gradients */
      background: -webkit-linear-gradient(left top, $top, $bottom); /* For Safari 5.1 to 6.0 */
      background: -o-linear-gradient(bottom right, $top, $bottom); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(bottom right, $top, $bottom); /* For Firefox 3.6 to 15 */
      background: linear-gradient(to bottom right, $top, $bottom); /* Standard syntax */
}

/////////////////// Box shadow
// usage: .classname{ @include shadow; } 
@mixin shadow {
  box-shadow:0 4px 15px rgba(#333, .4);
  -webkit-box-shadow:0 4px 15px rgba(#333, .4);
  -moz-box-shadow:0 4px 15px rgba(#333, .4);
}

@mixin shadow-light {
  box-shadow:0 6px 12px 0 rgba(0, 0, 0, .1);
  -webkit-box-shadow:0 6px 12px 0 rgba(0, 0, 0, .1);
  -moz-box-shadow:0 6px 12px 0 rgba(0, 0, 0, .1);
}


/////////////////// Clearfix
// usage: .myclear{ @include clearfix; } 
@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

